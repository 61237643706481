import forList from "@/mixin/forList";

export default {
    name: "SuccCommList",
    noFetchFirst: true,
    mixins: [forList],

    data() {
        return {
            SP_: {
                searchStartTime: '',
                searchEndTime: '',
                searchType: 1,
                searchValue: '',
                searchTimeType: 1,
                searchRegin: [],
                searchIsGrant: '',
            },
            searchTypeEnum: [
                { name: "客户姓名", value: 1 },
                { name: "订单编号", value: 2 },
                { name: "结佣员工", value: 3 },
            ],
            dateTypeEnum: [
                { name: "业绩核算月份", value: 1 },
                { name: "结算月份", value: 2 },
                { name: "实际发放月份", value: 3 },
            ],
            searchIsGrantarr: [
                { name: "全部", value: "" },
                { name: "是", value: 1 },
                { name: "否", value: 0 },
            ],
            showAdvanceSearch: false,
            regionList: [], // 区域选项     name, id
            // seasonList: [],
            countComWhite: '',
        };
    },
    created() {
        const { params } = this.$route;
        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.showAdvanceSearch = true;
            this.handleSearch();
        }
        else this.fetchData();
        this.fetchEnum()
    },

    methods: {
        // 点击复制
        copyCode(code) {
            var input = document.createElement("input"); // 创建input对象
            input.value = code; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.closeAll();
            this.$message.success('复制成功！');
        },

        async fetchEnum() {
            const loading = this.$loading();

            const res = await this.$axios({
                url: "/api/order/queryOrderParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.regionList = res.data.regionList
                this.countComWhite = res.data.countComWhite
                loading.close();
                // this.seasonList = res.data.seasonList
            } else if (res.code !== 1003)
                throw res
        },
        async fetchData() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/orderComm/querySuccCommList",
                    method: "post",
                    data: {
                        ...this.extractParams(),
                    }
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.orderCommApplyList;
                    if (this.regionList.length > 0) {
                        loading.close();
                    }
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
                if (this.regionList.length > 0) {
                    loading.close();
                }
            }
        },
        // 点击高级搜索
        handleGJBtn() {
            if (this.showAdvanceSearch)
                this.closeAdvanceSearch()
            else this.handleAdvancedSearchBtn()
        },
        handleSearchReginChange(e) {
            this.SP_.searchDept = [];
            this.departmentEnum = [];

            this.SP_.searchQueue = [];
            this.queueDept = []
            if (e.length == 1) this.fetchDepartmentEnum(e[0]);
        },
        clearSP_trim() {
            this.SP_.searchType = 1,
                this.SP_.searchTimeType = 1
        },

    }
};